export default function (parms = {}) {
  const { xData = [], yData = {} } = parms
  const { receive = [], reject = [] } = yData
  const option = {
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      show: true,
      left: 10
    },
    grid: {
      top: 35,
      bottom: 25,
      left: 40,
      right: 20
    },
    toolbox: {
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: xData
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        name: '接受需求',
        type: 'line',
        stack: '总量',
        data: receive,
        lineStyle: {
          color: '#3473E6',
          shadowColor: 'rgba(52, 115, 230, 0.2)',
          shadowBlur: 2,
          shadowOffsetY: 13
        },
        itemStyle: {
          color: '#3473E6'
        }
      },
      {
        name: '拒绝需求',
        type: 'line',
        stack: '总量',
        data: reject,
        lineStyle: {
          color: '#FF4343',
          shadowColor: 'rgba(255, 67, 67, 0.2)',
          shadowBlur: 2,
          shadowOffsetY: 13
        },
        itemStyle: {
          color: '#FF4343'
        }
      }
    ]
  }

  return option
}
