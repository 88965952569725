// 机构信用详细信息
<template>
  <div class="detail">
    <detail-header title="机构信用详细信息" />
    <div class="detail_main page_width" v-loading="loading">
      <div class="main_content">
        <div class="main_content_left">
          <div class="desc panel">
            <div class="panel_header">
              <info-header title="服务机构信用评价" />
            </div>
            <div class="tw-flex tw-items-center" style="margin:10px 0;">
              <img
                class="desc_img"
                :src="formData.avatarUrl"
                alt=""
                style="object-fit: cover;"/>
              <p
                class="desc_name text_size_16 tw-font-bold"
                :title="formData.organizationName">
                {{formData.organizationName}}
              </p>
            </div>
            <p class="desc_org text_size_14">
              {{formData.organization ? formData.organization.description : ''}}
            </p>
            <div class="desc_divider"></div>
            <div class="desc_btn">
              <el-button
                size="small"
                @click="onScore">
                平台评价
              </el-button>
            </div>
            <div class="desc_chart" ref="chart"></div>
            <div class="desc_score">
              综合评分
              <span class="tw-font-bold">
                {{formData.organization ? formData.organization.avgScore : ''}}
              </span>
            </div>
          </div>
        </div>
        <div class="main_content_right">
          <div class="info panel">
            <div class="panel_header">
              <info-header title="机构基础信息" />
            </div>
            <div class="table text_size_14">
              <div class="table_row">
                <div class="table_col">
                  <div class="table_col_label">机构名称</div>
                  <div class="table_col_value">
                    {{formData.organization ? formData.organization.name : ''}}
                  </div>
                </div>
              </div>
              <div class="table_row">
                <div class="table_col">
                  <div class="table_col_label">法定代表人</div>
                  <div class="table_col_value">
                    {{formData.organization ? formData.organization.legalPerson : ''}}
                  </div>
                </div>
              </div>
              <div class="table_row">
                <div class="table_col">
                  <div class="table_col_label">地址</div>
                  <div class="table_col_value">
                    {{formData.contactAddress}}
                  </div>
                </div>
              </div>
              <div class="table_row">
                <div class="table_col">
                  <div class="table_col_label">服务方向</div>
                  <div class="table_col_value">
                    {{formData.organization ? formData.organization.serviceDirection : ''}}
                  </div>
                </div>
              </div>
              <div class="table_row">
                <div class="table_col">
                  <div class="table_col_label">组织类型</div>
                  <div class="table_col_value">
                    {{formData.organization ? formData.organization.orgCategory : ''}}
                  </div>
                </div>
              </div>
              <div class="table_row">
                <div class="table_col">
                  <div class="table_col_label">产业特长</div>
                  <div class="table_col_value">
                    {{formData.organization ? getTechnicalNames(formData.organization.industryExpertise) : ''}}
                  </div>
                </div>
              </div>
            </div>
            <p class="info_score text_size_14">
              基础信息完善度：
              <span>{{formData.organization ? formData.organization.infoScore : ''}}</span>
            </p>
          </div>
          <div class="comment panel">
            <div class="panel_header">
              <info-header title="挂靠成员评价" />
            </div>
            <!-- 基本信息 -->
            <div class="comment_base">
              <el-select
                style="width:140px;"
                size="small"
                class=" tw-mb-10px"
                v-model="userId"
                placeholder="请选择成员">
                <el-option
                  v-for="item in userOptions"
                  :key="item.id"
                  :label="item.username"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-row type="flex" justify="space-between">
                <div class="comment_score text_size_14 tw-pt-10px">
                  <p class="comment_score_item">
                    服务质量
                    <span>{{curUserScore.serviceQuality}}</span>
                  </p>
                  <p class="comment_score_item">
                    服务效果
                    <span>{{curUserScore.serviceEffect}}</span>
                  </p>
                  <p class="comment_score_item">
                    服务态度
                    <span>{{curUserScore.serviceAttitude}}</span>
                  </p>
                  <p class="comment_score_item">
                    综合得分
                    <span>{{curUserScore.avgScore}}</span>
                  </p>
                </div>
                <div class="comment_statistics">
                  <div class="statistics_count tw-flex tw-justify-start tw-items-center">
                    <div class="statistics_count_article">
                      <p class="tag">接受需求</p>
                      <p class="count">{{ curUserScore.received }}</p>
                    </div>
                    <div class="statistics_count_check">
                      <p class="tag">拒绝需求</p>
                      <p class="count">{{ curUserScore.rejected }}</p>
                    </div>
                  </div>
                  <div class="statistics_chart">
                    <el-select
                      style="width:200px;"
                      size="small"
                      class="tw-mb-10px"
                      v-model="dateRange"
                      placeholder="请选择时间范围"
                      @change="onDateChange">
                      <el-option
                        v-for="item in dateOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                    <div ref="lineChart" class="line_chart_box"></div>
                  </div>
                </div>
              </el-row>
            </div>
            <!-- 评价列表 -->
            <div class="comment_list">
              <div
                class="comment_item"
                v-for="item in userData.commentList"
                :key="item.id">
                <div class="comment_item_score">
                  <div class="comment_item_score_item">
                    <p class="text_size_14">服务质量</p>
                    <el-rate
                      allow-half
                      :value="item.serviceQuality / 2"
                      disabled>
                    </el-rate>
                  </div>
                  <div class="comment_item_score_item">
                    <p class="text_size_14">服务效果</p>
                    <el-rate
                      allow-half
                      :value="item.serviceEffect / 2"
                      disabled>
                    </el-rate>
                  </div>
                  <div class="comment_item_score_item">
                    <p class="text_size_14">服务态度</p>
                    <el-rate
                      allow-half
                      :value="item.serviceAttitude / 2"
                      disabled>
                    </el-rate>
                  </div>
                </div>
                <p class="comment_item_content text_size_14">{{item.content}}</p>
                <p class="comment_item_time text_size_14">{{item.createdAt}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <platform-score-dialog
      :visible.sync="dialogVisible"
      :score-form.sync="scoreForm"
      :orgId="formData.organizationId"
      @confirm="scoreConfirm">
    </platform-score-dialog>
  </div>
</template>

<script>
import * as api from '@/api'
import * as echarts from 'echarts'
import PlatformScoreDialog from './platform-score-dialog.vue'
import geLineOptions from './options/line-options'
import dayjs from 'dayjs'
// 加载季度插件
const quarterOfYear = require('dayjs/plugin/quarterOfYear')
dayjs.extend(quarterOfYear)

export default {
  components: {
    PlatformScoreDialog
  },
  data () {
    return {
      loading: false,
      dialogVisible: false,
      lineChart: null, // 折线图实例
      formData: {}, // 机构信息
      scoreForm: {
        serviceQuality: 0,
        serviceEffect: 0,
        serviceAttitude: 0,
        platformScore: 0,
        infoScore: 0
      },
      // 用户数据
      userData: {
        // 人员评价列表
        commentList: []
      },
      userId: '', // 当前所选择用户id
      userOptions: [], // 机构下的用户列表
      dateRange: 0, // 当前所选时间范围
      dateOptions: [
        {
          label: '本季度',
          value: 0
        },
        {
          label: '本年',
          value: 1
        },
        {
          label: '去年',
          value: 2
        }
      ]
    }
  },
  computed: {
    // 服务用户id
    id () {
      return this.$route.params.id
    },
    // 技术领域
    technicalList () {
      return this.$store.state.technicalList
    },
    getTechnicalNames () {
      return (technical) => {
        const ids = technical ? technical.split(',') : []
        let result = ''

        this.technicalList.forEach(item => {
          if (ids.includes(item.id)) {
            result += result === '' ? item.name : ',' + item.name
          }
        })
        return result
      }
    },
    // 当前用户的打分及拒绝的需求及接受的需求
    curUserScore () {
      let curUserScore = {
        serviceQuality: 0,
        serviceEffect: 0,
        serviceAttitude: 0,
        received: 0,
        rejected: 0,
        avgScore: 0
      }
      if (this.userId) {
        curUserScore = this.userOptions.find(item => item.id === this.userId)
      }

      return curUserScore
    },
    // 根据所选的时间范围 给出开始和结束时间
    dateRangeObj () {
      const obj = {
        start: '',
        end: ''
      }
      // 季度开始和季度结束
      if (this.dateRange === 0) {
        obj.start = dayjs().startOf('quarter').format('YYYY-MM-DD')
        obj.end = dayjs().endOf('quarter').format('YYYY-MM-DD')
      // 今年
      } else if (this.dateRange === 1) {
        obj.start = dayjs().startOf('year').format('YYYY-MM-DD')
        obj.end = dayjs().endOf('year').format('YYYY-MM-DD')
      // 去年
      } else if (this.dateRange === 2) {
        obj.start = dayjs().add(-1, 'y').startOf('year').format('YYYY-MM-DD')
        obj.end = dayjs().add(-1, 'y').endOf('year').format('YYYY-MM-DD')
      }

      return obj
    }
  },
  watch: {
    // 当前所选用户id发生变化
    userId (value) {
      if (value) {
        // 获取评论列表
        this.getOrgMemberCommentList()
        // 获取统计图
        this.getOrgMemberCreditStatistic()
      }
    }
  },
  mounted () {
    // 获取此服务用户信息，取里面的机构信息
    this.getUserItemInfo()
  },
  methods: {
    // 获取机构信息
    getUserItemInfo () {
      this.loading = true
      api.getUserItemInfo(this.id).then(res => {
        if (res.data.code === 0) {
          this.formData = res.data.data
          this.$nextTick(() => {
            this.initChart(this.formData.organization)
          })
          // 通过机构id 拿到机构人员列表
          this.getOrgCreditMemberList(this.formData.organizationId)
        } else {
          this.$message.error(res.data.message)
        }
        this.loading = false
      }).catch(err => {
        this.loading = false
        console.log(err)
        this.$message.error('请求出错')
      })
    },
    // 根据机构id 获取到机构下人员列表
    getOrgCreditMemberList (id) {
      api.getOrgCreditMemberList(id).then(res => {
        if (res.data.code === 0) {
          console.log('机构下人员列表', res.data.data)
          this.userOptions = res.data.data.list
          if (this.userOptions && this.userOptions.length > 0) {
            this.userId = this.userOptions[0].id
          }
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      })
    },
    // 根据用户id 拿到评价列表
    getOrgMemberCommentList () {
      api.getOrgMemberCommentList(this.userId, {
        pageNum: 1,
        pageSize: 100000
      }).then(res => {
        if (res.data.code === 0) {
          console.log('用户评价列表', res.data.data)
          this.userData.commentList = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      })
    },
    // 根据用户id 拿到统计图数据
    getOrgMemberCreditStatistic () {
      api.getOrgMemberCreditStatistic(this.userId, {
        ...this.dateRangeObj
      }).then(res => {
        if (res.data.code === 0) {
          console.log('用户统计图数据', res.data.data)
          // 渲染统计图
          this.renderLineChart(res.data.data)
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      })
    },
    // 初始化雷达图
    initChart ({ platformScore, infoScore, serviceEffect, serviceQuality, serviceAttitude }) {
      const chart = echarts.init(this.$refs.chart)

      const scores = {
        平台打分: platformScore,
        服务质量: serviceQuality,
        服务效果: serviceEffect,
        身份真实: infoScore,
        服务态度: serviceAttitude
      }

      chart.setOption({
        radar: {
          radius: '65%',
          axisName: {
            formatter: function (value, indicator) {
              return `{a|${value}}\n{b|${scores[value]}}`
            },
            rich: {
              a: {
                align: 'center',
                color: '#303133',
                lineHeight: 17
              },
              b: {
                align: 'center',
                color: '#3473E6',
                lineHeight: 22,
                fontWeight: 'bold',
                fontSize: 16
              }
            }
          },
          splitNumber: 4,
          axisNameGap: 0,
          indicator: [
            { name: '平台打分', max: 10 },
            { name: '服务态度', max: 10 },
            { name: '身份真实', max: 10 },
            { name: '服务效果', max: 10 },
            { name: '服务质量', max: 10 }
          ],
          shape: 'circle',
          splitArea: {
            show: false
          }
        },
        series: [{
          type: 'radar',
          data: [
            {
              name: 'score',
              value: [platformScore, serviceAttitude, infoScore, serviceEffect, serviceQuality]
            }
          ],
          itemStyle: {
            opacity: 0
          },
          lineStyle: {
            color: '#3473E6'
          },
          areaStyle: {
            color: 'rgba(52, 115, 230, 0.2)'
          }
        }]
      })
    },
    /**
     * 点击打分
     */
    onScore () {
      for (const key in this.scoreForm) {
        if (Object.hasOwnProperty.call(this.scoreForm, key)) {
          this.scoreForm[key] = this.formData.organization[key]
        }
      }
      this.dialogVisible = true
    },
    /**
     * 打分确认
     */
    scoreConfirm () {
      for (const key in this.scoreForm) {
        if (Object.hasOwnProperty.call(this.scoreForm, key)) {
          this.formData.organization[key] = this.scoreForm[key]
        }
      }
      this.initChart(this.scoreForm)
    },
    // 时间范围变化了
    onDateChange () {
      this.getOrgMemberCreditStatistic()
    },
    // 渲染折线图
    renderLineChart (data) {
      if (!this.chart) {
        this.chart = echarts.init(this.$refs.lineChart)
      }
      const options = geLineOptions(data)
      this.chart.setOption(options, true)
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  &_main {
    padding: 30px 0;
    .main_content {
      width: 1120px;
      margin: 0 auto;
      display: flex;
      &_left {
        width: 300px;
      }
      &_right {
        width: 800px;
        margin-left: 20px;
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.panel {
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  &_header {
    height: 60px;
    display: flex;
    align-items: center;
    padding-left: 13px;
  }
}

.desc {
  padding: 0 20px 16px 20px;
  &_img {
    width: 48px;
    height: 48px;
    border-radius: 4px;
  }
  &_name {
    flex: 1;
    margin-left: 16px;
    color: #303133;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &_org {
    color: #303133;
    line-height: 20px;
  }
  &_divider {
    margin: 40px 0 0 0;
    border-top: 1px dashed #DCDFE6;
  }
  &_btn {
    padding: 20px 0;
    text-align: right;
  }
  &_chart {
    width: 260px;
    height: 260px;
    margin-bottom: 20px;
  }
  &_score {
    width: 90px;
    height: 24px;
    margin: 0 auto;
    padding: 2px 0;
    color: #3473E6;
    background: rgba($color: #3473E6, $alpha: 0.1);
    border: 1px solid rgba($color: #3473E6, $alpha: 0.3);
    border-radius: 2px;
    @apply tw-text-14px tw-leading-20px tw-text-center;
  }
}

.info {
  width: 800px;
  padding: 0 30px;
  margin-bottom: 20px;
  .table {
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    &_row {
      // height: 48px;
      min-height: 48px;
      display: flex;
      &:not(:last-child) {
        border-bottom: 1px solid #DCDFE6;
      }
    }
    &_col {
      flex: 1;
      display: flex;
      &:not(:first-child) {
        border-left: 1px solid #DCDFE6;
      }
      &_label {
        min-width: 115px;
        padding: 0 20px;
        color: #909399;
        background-color: #F7F8F9;
        border-right: 1px solid #DCDFE6;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &_value {
        flex: 1;
        padding: 14px 20px;
        color: #909399;
        display: flex;
        align-items: center;
      }
    }
  }
  &_score {
    padding: 20px 0;
    line-height: 20px;
    color: #909399;
    & > span {
      color: #3473E6;
      font-weight: bold;
    }
  }
}

.comment {
  width: 800px;
  min-height: 180px;
  padding: 0 30px;
  &_score {
    padding: 0 40px 0 10px;
    &_item {
      line-height: 30px;
      color: #606266;
      margin-bottom: 10px;
      & > span {
        color: #3473E6;
        margin-left: 12px;
        font-size: 20px;
        font-weight: bold;
      }
      &:last-child {
        & > span {
          color: #FF6F00;
        }
      }
    }
  }
  &_list {
    flex: 1;
    .comment_item {
      padding: 30px 0 20px 0;
      border-bottom: 1px solid #DCDFE6;
      &:first-child {
        padding-top: 0;
      }
      &_score {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &_item {
          flex: 1;
          display: flex;
          align-items: center;
          & > p {
            width: 66px;
            color: #909399;
          }
        }
      }
      &_content {
        color: #606266;
        margin: 16px 0 20px 0;
        line-height: 20px;
        word-break: break-all;
      }
      &_time {
        color: #909399;
        line-height: 20px;
      }
    }
    .comment_pagination {
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.comment_base {
  padding-bottom: 20px;
  border-bottom: 1px solid #DCDFE6;
  .comment_statistics {
    flex: 0 0 580px;
    // .statistics_count {
    //   display: flex;
    //   margin-bottom: 10px;
    //   .count_box {
    //     width: 240px;
    //     height: 80px;
    //     margin-right: 10px;
    //     border: 1px solid #000;
    //   }
    // }
    .statistics_count {
      margin-bottom: 10px;
      > * {
        width: 240px;
        height: 80px;
        position: relative;
        color: #ffffff;
        margin-right: 10px;
        > .tag {
          padding-left: 12px;
          margin-top: 12px;
          width: 100%;
          height: 20px;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
        }
        > .count {
          overflow: hidden;
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding-left: 20px;
          margin-top: 4px;
          width: 100%;
          height: 33px;
          font-size: 24px;
          font-weight: bold;
          line-height: 33px;
          opacity: 1;
        }
      }
      &_article {
        background: url("../../assets/images/info-stats/count_article_sended.png")
          no-repeat;
      }
      &_check {
        background: url("../../assets/images/info-stats/count_check_pending.png")
          no-repeat;
      }
    }
    .statistics_chart {
      padding: 10px;
      background-color: #F7F8F9;
      .line_chart_box {
        width: 100%;
        height: 200px;
      }
    }
  }
}
</style>
